import React, { useEffect, useMemo } from "react";
import {
  Vector3,
  Mesh,
  CatmullRomCurve3,
  PerspectiveCamera as RawPerspectiveCamera,
  Shape,
} from "three";
import { Encoder } from "../../encoder";
import { useFrame, useThree } from "@react-three/fiber";
import {
  PerspectiveCamera,
  Bounds,
  useTexture,
  Stats,
} from "@react-three/drei";
import { PointData, City } from "types";
import { OrbitControls } from "../OrbitControls";
import { themes, Theme } from "../../themes";
import Grid from "./components/Grid";
import Cities from "./components/Cities";
import Lights from "./components/Lights";
import PanthEnd from "./components/PathEnd";
import PositionPointer from "./components/PositionPointer";
import Surface from "./components/Surface";
import { angleToRad } from "../../utils/math";
import * as dat from "dat.gui";
import CustomExtrudeGeometry from "../CustomExtrudeGeometry.tsx";

type ParsedPoints = [number, number, number];

function getCenterPoint(mesh: Mesh) {
  const geometry = mesh.geometry;
  geometry.computeBoundingBox();
  const center = new Vector3();
  geometry.boundingBox!.getCenter(center);
  mesh.localToWorld(center);
  return center;
}

type Props = {
  data: PointData[];
  rendererRef: any;
  tiles: PointData[][];
  generatingVideo?: boolean;
  autoDownload?: boolean;
  onVideoFinish?: () => void;
  drawSurface?: boolean;
  drawLines?: boolean;
  drawLabels?: boolean;
  theme?: Theme;
  nearestCities?: City[];
  altitudeAreaFactor: number;
};

let frame = 0;

const MATCAP_OPTIONS = [
  "matcap1.png",
  "matcap2.png",
  "matcap3.png",
  "matcap4.png",
  "matcap5.png",
  "matcap6.png",
  "matcap7.png",
  "matcap8.png",
  "matcap9.png",
  "matcap10.png",
  "matcap11.png",
  "matcap12.png",
  "matcap13.png",
  "matcap14.png",
  "matcap15.png",
  "matcap16.png",
  "matcap17.png",
];

const getComputedAltitudeAreaFactor = (altitudeAreaFactor: number) => {
  if (altitudeAreaFactor > 10000) {
    return 3;
  }
  if (altitudeAreaFactor > 5000) {
    return 5;
  }
  return 10;
};

const Vizualization = ({
  data,
  rendererRef,
  tiles,
  generatingVideo = false,
  autoDownload = true,
  onVideoFinish,
  drawSurface = true,
  drawLines = true,
  drawLabels = true,
  theme = themes[0],
  nearestCities = [],
  altitudeAreaFactor = 1,
}: Props) => {
  const sphere = React.useRef<Mesh>(null!);
  const [pathRef, setPathRef] = React.useState<Mesh>(null!);
  const textRef = React.useRef<Mesh>(null!);
  const cameraRef = React.useRef<RawPerspectiveCamera>(null!);
  const gl = useThree((state) => state.gl);
  const encoderRef = React.useRef<Encoder | null>(null);
  const controlsRef = React.useRef(null!);
  const [positonZ, setPositionZ] = React.useState(0);
  const computedAltitudeAreaFactor =
    getComputedAltitudeAreaFactor(altitudeAreaFactor);
  const [selectedMatcap, setSelectedMatcap] = React.useState(() => {
    return localStorage.getItem("selectedMatcap") || MATCAP_OPTIONS[7];
  });
  const [selectedTubeMatcap, setSelectedTubeMatcap] = React.useState(() => {
    return localStorage.getItem("selectedTubeMatcap") || MATCAP_OPTIONS[7];
  });
  const [gridColor, setGridColor] = React.useState(() => {
    return localStorage.getItem("gridColor") || "#FFFFFF";
  });
  const [gridOpacity, setGridOpacity] = React.useState(() => {
    return parseFloat(localStorage.getItem("gridOpacity") || "0.1");
  });
  // Add new state for surface matcap color
  const [surfaceMatcapColor, setSurfaceMatcapColor] = React.useState(() => {
    return localStorage.getItem("surfaceMatcapColor") || "#ffffff"; // Default color
  });
  const matCapTexture = useTexture(`/img/${selectedMatcap}`);
  const tubeMatCapTexture = useTexture(`/img/${selectedTubeMatcap}`);

  const zoomFactor = useMemo(() => {
    return Math.abs(positonZ);
  }, [positonZ, data]);

  const centerPoint = useMemo(() => {
    if (!pathRef || !pathRef.geometry) {
      return;
    }
    const center = getCenterPoint(pathRef);
    return center;
  }, [pathRef, data, generatingVideo, zoomFactor]);

  useEffect(() => {
    rendererRef.current = gl;
  }, [gl, rendererRef]);

  const parsedTiles = useMemo(
    () =>
      tiles.map((row) => {
        return row.map((point) => {
          const data = [
            point.location.lat * 100000,
            point.location.lng * 100000,
          ];
          const parsedData = [
            data[0],
            data[1],
            point.elevation * computedAltitudeAreaFactor,
          ];
          return parsedData as ParsedPoints;
        });
      }),
    [tiles, theme],
  );

  useEffect(() => {
    if (typeof VideoEncoder === "undefined" || !generatingVideo) {
      return;
    }
    encoderRef.current = new Encoder({
      canvas: rendererRef.current!.domElement,
      duration: fullTime,
      framerate: 60,
      videoBitrate: 10_000_000,
      autoDownload,
    });

    encoderRef.current.prepare();
  }, [generatingVideo]);

  const parsed = useMemo(() => {
    const maped = data.map((point) => {
      const data = [point.location.lat * 100000, point.location.lng * 100000];
      const parsedData = [
        data[0],
        data[1],
        point.elevation * computedAltitudeAreaFactor,
      ];
      return parsedData as ParsedPoints;
    });
    return maped;
  }, [data]);

  const mappedToPoints = useMemo(
    () =>
      parsed.map(
        ([lat, long, alt]) =>
          new Vector3(Math.floor(lat), Math.floor(alt) + 2, Math.floor(long)),
      ),
    [parsed],
  );

  const citiesMappedToPoints = useMemo(
    () =>
      nearestCities.map(({ location, elevation }: any) => {
        const { lat, lng } = location;
        return new Vector3(
          Math.floor(lat * 100000),
          elevation * computedAltitudeAreaFactor,
          Math.floor(lng * 100000),
        );
      }),
    [nearestCities],
  );

  const tilesMappedToPoints = useMemo(
    () =>
      parsedTiles.map((row) =>
        row.map(([lat, long, alt]) => {
          return new Vector3(
            Math.floor(lat),
            Math.floor(alt),

            Math.floor(long),
          );
        }),
      ),
    [parsedTiles, drawSurface, drawLines],
  );

  const tilesMappedToPoints2 = useMemo(() => {
    return tilesMappedToPoints[0].map((_, index) =>
      tilesMappedToPoints.map((row) => row[index]),
    );
  }, [tilesMappedToPoints]);

  const fullTime = 15;
  const fullCameraTime = 45;

  useFrame(async ({ clock, camera }) => {
    if (camera.position.z !== 0 && !positonZ) {
      setPositionZ(-camera.position.distanceTo(centerPoint));
    }

    if (generatingVideo) {
      frame = frame + 1;
    }

    const rotateToAngle = (angle: number) => {
      let currentRotation = (controlsRef.current as any).getAzimuthalAngle();
      currentRotation = (currentRotation + Math.PI * 2) % (Math.PI * 2);
      const rotationDelta = angle + currentRotation;

      (controlsRef.current as any).rotateLeft(rotationDelta);
    };
    const elapsed = clock.getElapsedTime();
    const currentLoopTime = elapsed % fullTime;
    const currentCameraLoopTime = elapsed % 45;
    const percentage = generatingVideo
      ? (frame / (60 * fullTime)) % 1
      : currentLoopTime / fullTime;
    const cameraPercentage = generatingVideo
      ? (frame / (60 * fullCameraTime)) % 1
      : currentCameraLoopTime / fullCameraTime;
    rotateToAngle(angleToRad(cameraPercentage * 360));
    if (sphere.current) {
      if (textRef) {
        textRef.current.lookAt(camera.position);
        textRef.current.text = `${Math.floor(
          data[Math.floor(percentage * data.length)].elevation,
        )} m`;
      }

      sphere.current.position.set(
        ...pathCurve.getPointAt(percentage).toArray(),
      );

      // video generation
      if (generatingVideo && encoderRef.current) {
        const finished = await encoderRef.current!.addFrame();
        if (finished && onVideoFinish) {
          onVideoFinish();
          if (!autoDownload) {
            const stringifiedBlob =
              await encoderRef.current.getStringifiedBlob();
            (window as any).onRenderFinish(stringifiedBlob);
          }
        }
      }
    }
  });
  const pathCurve = useMemo(() => {
    return new CatmullRomCurve3(mappedToPoints, false, "centripetal", 0.2);
  }, [mappedToPoints]);

  const gridFilterFactor = useMemo(() => {
    return Math.max(Math.floor(positonZ / 34300), 1);
  }, [positonZ]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      return;
    }
    const gui = new dat.GUI();
    const matcapOptions = {
      surfaceMatcap: selectedMatcap,
      tubeMatcap: selectedTubeMatcap,
      gridColor: gridColor,
      gridOpacity: gridOpacity,
      surfaceMatcapColor: surfaceMatcapColor, // Add surfaceMatcapColor to GUI options
    };

    gui
      .add(matcapOptions, "surfaceMatcap", MATCAP_OPTIONS)
      .onChange((value) => {
        setSelectedMatcap(value);
        localStorage.setItem("selectedMatcap", value);
      });

    gui.add(matcapOptions, "tubeMatcap", MATCAP_OPTIONS).onChange((value) => {
      setSelectedTubeMatcap(value);
      localStorage.setItem("selectedTubeMatcap", value);
    });

    gui.addColor(matcapOptions, "gridColor").onChange((value) => {
      setGridColor(value);
      localStorage.setItem("gridColor", value);
    });

    gui.add(matcapOptions, "gridOpacity", 0, 1).onChange((value) => {
      setGridOpacity(value);
      localStorage.setItem("gridOpacity", value.toString());
    });

    // Add a color picker for the surface matcap material color
    gui.addColor(matcapOptions, "surfaceMatcapColor").onChange((value) => {
      setSurfaceMatcapColor(value);
      localStorage.setItem("surfaceMatcapColor", value);
    });

    return () => {
      gui.destroy();
    };
  }, []);

  // Load the matcap texture

  // Generate the ribbon geometry using the binormal vectors
  const shape = useMemo(() => {
    const width = zoomFactor / 550; // Adjust the width as needed
    const height = zoomFactor / 10000; // Keep the height small to make it flat
    const shape = new Shape();
    shape.moveTo(-width / 2, -height / 2);
    shape.lineTo(-width / 2, height / 2);
    shape.lineTo(width / 2, height / 2);
    shape.lineTo(width / 2, -height / 2);
    shape.lineTo(-width / 2, -height / 2); // Close the shape
    return shape;
  }, [zoomFactor]);
  // Create the extruded geometry along the path curve

  const steps = 10000; // Number of steps along the path
  const up = new Vector3(0, 1, 0); // Global up vector

  const frames = useMemo(() => {
    const tangents = [];
    const normals = [];
    const binormals = [];

    for (let i = 0; i <= steps; i++) {
      const u = i / steps;
      const tangent = pathCurve.getTangentAt(u).normalize();
      tangents.push(tangent);

      // Ensure the up vector is always (0, 1, 0)
      const binormal = up.clone();
      binormals.push(binormal);

      const normal = new Vector3().crossVectors(binormal, tangent).normalize();
      normals.push(normal);
    }

    return { tangents, normals, binormals };
  }, [pathCurve]);

  const geometry = useMemo(() => {
    return CustomExtrudeGeometry(shape, pathCurve, frames, steps);
  }, [shape, pathCurve, frames, steps]);

  return (
    <>
      <group>
        {process.env.NODE_ENV === "development" && <Stats />}
        {data.length && (
          <>
            <PerspectiveCamera
              position={[857, 500, 0]}
              makeDefault
              fov={20}
              near={400}
              far={21000}
              ref={cameraRef}
            />
            <color attach="background" args={[`#${theme.background}`]} />
            <OrbitControls
              enableDamping={false}
              minPolarAngle={0.9}
              maxPolarAngle={0.9}
              minDistance={500}
              target={
                pathRef
                  ? new Vector3(
                      centerPoint.x,
                      centerPoint.y - (generatingVideo ? zoomFactor / 20 : 0), // Adjust target Y to shift scene up
                      centerPoint.z,
                    )
                  : undefined
              }
              maxDistance={3000000}
              ref={controlsRef}
            />
            <Lights />
            <Bounds fit clip observe damping={0} margin={1.1}>
              <mesh
                geometry={geometry}
                ref={(newRef) => setPathRef(newRef as Mesh)}
                renderOrder={1000}
              >
                <meshMatcapMaterial matcap={tubeMatCapTexture} side={2} />
              </mesh>
            </Bounds>
            <PanthEnd
              position={mappedToPoints[0]}
              zoomFactor={zoomFactor}
              theme={theme}
            />
            <PanthEnd
              position={mappedToPoints[mappedToPoints.length - 1]}
              zoomFactor={zoomFactor}
              theme={theme}
            />
            {drawLines && (
              <Grid
                tilesMappedToPoints={tilesMappedToPoints}
                tilesMappedToPoints2={tilesMappedToPoints2}
                gridFilterFactor={gridFilterFactor}
                theme={theme}
                gridColor={gridColor}
                gridOpacity={gridOpacity}
              />
            )}
            {nearestCities && drawLabels && (
              <Cities
                citiesMappedToPoints={citiesMappedToPoints}
                nearestCities={nearestCities}
                theme={theme}
                zoomFactor={zoomFactor}
              />
            )}
            <PositionPointer
              zoomFactor={zoomFactor}
              theme={theme}
              passRef={sphere}
              passTextRef={textRef}
            />
            {pathRef && drawSurface && centerPoint && (
              <Surface
                position={centerPoint}
                tilesMappedToPoints={tilesMappedToPoints}
                tilesMappedToPoints2={tilesMappedToPoints2}
                theme={theme}
                drawLabels={drawLabels}
                texture={matCapTexture}
                color={surfaceMatcapColor} // Pass the selected color to Surface component
              />
            )}
          </>
        )}
      </group>
    </>
  );
};

export default Vizualization;
